@use 'sass:map';

@use 'variables';

.svg-sprite {
    position: absolute;
    left: -100%;
    top: -100%;
}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    stroke: currentColor;
}
