@use 'sass:map';

@use 'variables';

.relative {
    position: relative;
}

.mt-1 {
    margin-top: map.get(variables.$spacings, 1);
}

.mb-2 {
    margin-bottom: map.get(variables.$spacings, 2);
}

.mb-3 {
    margin-bottom: map.get(variables.$spacings, 3);
}

.mb-4 {
    margin-bottom: map.get(variables.$spacings, 4);
}

.mb-5 {
    margin-bottom: map.get(variables.$spacings, 5);
}

.mb-7 {
    margin-bottom: map.get(variables.$spacings, 7);
}

.d-f {
    display: flex;
}

.d-b {
    display: block;
}

.d-ib {
    display: inline-block;
}

.d-n {
    display: none;
}

@media (min-width: #{variables.$breakpoint3}) {
    .d-md-b {
        display: block;
    }

    .d-md-n {
        display: none;
    }
}

.tt-u {
    text-transform: uppercase;
}

.w-100p {
    width: 100%;
}

.h-100p {
    height: 100%;
}

@media (min-width: #{variables.$breakpoint3}) {
    .w-md-60p {
        width: 60%;
    }
}

.lh-1 {
    line-height: variables.$lineHeight1;
}

.f-col {
    flex-flow: column;
}

.f-grow {
    flex-grow: 1;
}

.jc-sb {
    justify-content: space-between;
}

@media (min-width: #{variables.$breakpoint3}) {
    .f-md-grow-0 {
        flex-grow: 0;
    }
}

.t-c {
    text-align: center;
}

.c-grad {
    @include variables.gradColor;
}

.us-none {
    -webkit-user-select: none;
    user-select: none;
}

.us-text {
    -webkit-user-select: text;
    user-select: text;
}

.ws-n {
    white-space: nowrap;
}

.cur-d {
    cursor: default;
}

.pe-n {
    pointer-events: none;
}

.wrap-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.topline {
    transform: translateY(#{(variables.$lineHeight3 - 1%) * 100});

    &__item {
        position: relative;
        top: (1em - variables.$lineHeight3);
        vertical-align: top;
    }
}
