.fadeInUp {
    animation: 0.6s fadeInUp;
    animation-play-state: paused;

    &.in {
        animation-play-state: running;
    }
}

@media (prefers-reduced-motion: reduce) {
    *:not(symbol):not(circle):not(path) {
        animation-duration: 0.000001s !important;
        transition-duration: 0.000001s !important;
    }

    .fadeInUp {
        animation-play-state: running;
    }
}

@keyframes canvasSteppedShake {
    0% {
        transform: translate3d(0.5%, 0, 0);
    }

    10% {
        transform: translate3d(-0.5%, 0, 0);
    }

    20% {
        transform: translate3d(1.5%, 1%, 0);
    }

    30% {
        transform: translate3d(-0.25%, 0, 0);
    }

    40% {
        transform: translate3d(1.5%, 0.1%, 0);
    }

    50% {
        transform: translate3d(0.25%, 0, 0);
    }

    60% {
        transform: translate3d(0.5%, -0.4%, 0);
    }

    70% {
        transform: translate3d(-1.5%, 0.5%, 0);
    }

    80% {
        transform: translate3d(-0.25%, -0.25%, 0);
    }

    90% {
        transform: translate3d(-0.55%, 0.1%, 0);
    }

    100% {
        transform: translate3d(0.25%, -0.6%, 0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 25%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rippleLogo {
    0% {
        transform: scale(0.6);
    }

    40% {
        transform: scale(1.09);
    }

    100% {
        transform: none;
    }
}

@keyframes rippleLogoInner {
    0% {
        transform: scale(0.4);
    }

    10% {
        transform: none;
    }

    34% {
        transform: scale(0.93);
    }

    45% {
        transform: none;
    }

    56% {
        transform: scale(0.98);
    }

    67% {
        transform: none;
    }

    78% {
        transform: scale(0.99);
    }

    100% {
        transform: none;
        opacity: 1;
    }
}

@keyframes loadingPulse {
    30% {
        filter: saturate(0.2) brightness(5);
    }
    85% {
        filter: saturate(0.2) brightness(5);
    }
}

@keyframes bgPosFadeIn {
    100% {
        background-position: 96% 96%;
    }
}

@keyframes bgGradPulse {
    45% {
        background-position: 80% 80%;
    }
    92% {
        background-position: 100% 100%;
    }
}
