body, html {
  width: 100%;
  height: 100%;
  margin: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: 0 0 1px #00000003;
  color: #071633;
  font-size: 100%;
  overflow: hidden;
}

body {
  box-sizing: border-box;
  letter-spacing: .04em;
  padding: 15px;
  font-family: Inconsolata, Ubuntu Mono, Trebuchet MS, Tahoma, monospace;
  font-size: 1.25rem;
  line-height: 1.2;
  position: relative;
  overflow: hidden;
}

@media (height >= 600px) {
  body {
    padding: 3vh;
    font-size: 3.7vh;
  }
}

@media (height >= 1440px) {
  body {
    font-size: 3.3vh;
  }
}

@media (orientation: landscape) and (width >= 920px) {
  body {
    letter-spacing: .1em;
    padding: 5.55vh;
    font-size: 3.4vh;
  }
}

main {
  box-sizing: border-box;
  background-color: #efefef;
  flex-flow: column;
  height: 100%;
  padding: 15px 20px 30px;
  display: flex;
  overflow: auto;
}

@media (orientation: landscape) and (width >= 920px) {
  main {
    padding-left: 90px;
  }
}

footer, header {
  letter-spacing: .1em;
  text-transform: uppercase;
  flex-flow: row-reverse;
  justify-content: space-between;
  font-size: .5rem;
  line-height: 2.25em;
  display: flex;
  position: relative;
}

@media (height >= 600px) {
  footer, header {
    font-size: 1.5vh;
  }
}

header {
  position: absolute;
  inset: -1lh 0 0;
}

details {
  display: inline-block;
}

summary {
  list-style: none;
  display: inline-block;
}

summary::-webkit-details-marker {
  display: none;
}

summary::marker {
  display: none;
}

h1, h2 {
  letter-spacing: .08em;
  text-transform: uppercase;
  margin: 0;
  font-size: 1.625rem;
  font-weight: 400;
}

@media (height >= 600px) {
  h1, h2 {
    font-size: 5vh;
  }
}

@media (orientation: landscape) and (width >= 920px) {
  h1, h2 {
    font-size: 6vh;
  }
}

a {
  text-decoration: none;
}

main a {
  color: #071633;
  text-transform: uppercase;
}

main a:active, main a:hover {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background-color: #053db3;
  background-image: linear-gradient(140deg, #ff5f49 35%, #053db3 80%);
  -webkit-background-clip: text;
  background-clip: text;
}

main a:active .icon, main a:hover .icon {
  fill: url("#grad");
  stroke: url("#grad");
}

label {
  text-transform: uppercase;
  margin-bottom: -1.2em;
}

@media (orientation: landscape) and (width >= 1920px) {
  label small {
    font-size: 1rem;
  }
}

input, textarea {
  font: inherit;
  color: inherit;
  caret-color: #ff5f49;
  background: none;
  border: 0 solid;
  border-bottom-width: 2px;
  width: 100%;
  margin-bottom: 25px;
  padding: 0;
  transition: background .15s ease-in-out;
  position: relative;
}

@media (orientation: landscape) and (width >= 920px) {
  input, textarea {
    border-bottom-width: 3px;
  }
}

@media (orientation: landscape) and (width >= 1920px) {
  input, textarea {
    border-bottom-width: 5px;
    margin-bottom: 45px;
  }
}

input:focus, textarea:focus {
  border-image: linear-gradient(to right, #ff5f49 25%, #053db3 75%) 1;
  outline: none;
}

input:not(:placeholder-shown), textarea:not(:placeholder-shown), :-webkit-any(input:-webkit-autofill, textarea:-webkit-autofill) {
  background: #fff;
}

input:not(:placeholder-shown), textarea:not(:placeholder-shown), :is(input:autofill, textarea:autofill) {
  background: #fff;
}

@media (orientation: landscape) and (width >= 1920px) {
  label, input, textarea {
    font-size: 1.875rem;
  }
}

textarea {
  resize: none;
}

small {
  letter-spacing: .04em;
  font-size: .5em;
}

@media (orientation: landscape) and (width >= 920px) {
  small {
    letter-spacing: .1em;
  }
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.btn {
  -webkit-appearance: none;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
  padding: .5em;
}

.btn.fill {
  color: #fff;
  background-color: #071633;
}

.btn.fill.active, .btn.fill:active, .btn.fill:hover, body.t-dark .btn.fill[data-theme="dark"], body.t-light .btn.fill[data-theme="light"], body:not(.t-dark):not(.t-light) .btn.fill[data-theme="auto"] {
  background-image: linear-gradient(140deg, #ff5f49 25%, #053db3 90%);
}

.btn.outline {
  padding: 0;
}

.btn.outline.active, .btn.outline:active, .btn.outline:hover, body.t-dark .btn.outline[data-theme="dark"], body.t-light .btn.outline[data-theme="light"], body:not(.t-dark):not(.t-light) .btn.outline[data-theme="auto"] {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background-color: #053db3;
  background-image: linear-gradient(140deg, #ff5f49 35%, #053db3 80%);
  -webkit-background-clip: text;
  background-clip: text;
}

.btn.outline.active .icon, .btn.outline:active .icon, .btn.outline:hover .icon, body.t-dark .btn.outline[data-theme="dark"] .icon, body.t-light .btn.outline[data-theme="light"] .icon, body:not(.t-dark):not(.t-light) .btn.outline[data-theme="auto"] .icon {
  fill: url("#grad");
  stroke: url("#grad");
}

.btn.text {
  color: inherit;
  background: none;
  padding: 0 .5em;
}

.btn.text.active, .btn.text:active, .btn.text:hover, body.t-dark .btn.text[data-theme="dark"], body.t-light .btn.text[data-theme="light"], body:not(.t-dark):not(.t-light) .btn.text[data-theme="auto"] {
  background: none;
  text-decoration: underline;
}

.btn--big {
  font-size: 1.875rem;
}

@media (orientation: landscape) and (width >= 920px) {
  .btn--big .icon {
    font-size: 2.5rem;
  }
}

.btn:disabled {
  opacity: .4;
  filter: saturate(.2);
  pointer-events: none;
  animation: 3s ease-in-out infinite loadingPulse;
}

.svg-sprite {
  position: absolute;
  top: -100%;
  left: -100%;
}

.icon {
  vertical-align: middle;
  fill: currentColor;
  stroke: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.content {
  flex-flow: column;
  flex-grow: 1;
  display: flex;
  position: relative;
}

@media (orientation: landscape) and (width >= 920px) {
  .content {
    z-index: 1;
    flex-flow: row-reverse;
    display: flex;
  }
}

.mobile-nav-wrapper {
  z-index: 2;
  flex-flow: column;
  display: flex;
  position: absolute;
  right: -5px;
}

.logo-wrapper {
  flex-flow: column;
  flex-grow: 1;
  height: 72px;
  max-height: 20vh;
  margin: auto 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (orientation: landscape) and (width >= 920px) {
  .logo-wrapper {
    width: 60%;
    height: 45vh;
    max-height: none;
    margin-top: calc(24.5vh - 30px);
    padding: 15px 0;
  }
}

@media (orientation: landscape) and (width >= 920px) and (height >= 480px) {
  .logo-wrapper {
    max-height: none;
    margin-top: calc(14.5vh - 15px);
    padding: 10vh 0;
  }
}

@media (orientation: landscape) and (width >= 920px) {
  .contact-col {
    flex-flow: column;
    flex-grow: 1;
    justify-content: space-evenly;
    display: flex;
  }
}

.contact-col__cta {
  max-width: 20ch;
  margin-top: -.2em;
  margin-left: 20px;
  display: none;
}

@media (width >= 480px) {
  .contact-col__cta {
    display: block;
  }
}

.icon-logomark {
  stroke: none;
  width: 100%;
  display: block;
}

.icon-logomark--img {
  flex-grow: 1;
  height: 100%;
}

.icon-logomark path, .icon-logomark use {
  visibility: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .icon-logomark path, .icon-logomark use {
    visibility: visible;
  }
}

.icon-logotype {
  box-sizing: border-box;
  color: #fff;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  font-size: 112.5vw;
  line-height: .2;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (orientation: landscape) {
  .icon-logotype {
    font-size: 112.5vh;
  }
}

.icon-logotype .icon {
  opacity: 0;
  stroke: none;
  width: 100%;
  height: 100%;
  animation-name: rippleLogo, fadeIn;
  animation-duration: .8s, .4s;
  animation-timing-function: cubic-bezier(.65, .43, .67, 1.53);
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-play-state: running, running;
  animation-delay: .6s, .6s;
  animation-fill-mode: forwards, forwards;
}

.icon-logotype__circle {
  transform-origin: bottom;
  animation: 2.5s ease-out .6s forwards rippleLogoInner;
}

@media (prefers-reduced-motion: reduce) {
  .icon-logotype__circle {
    animation: none;
  }
}

.title-box {
  max-width: 12rem;
}

@media (height >= 600px) {
  .title-box {
    max-width: 36vh;
  }
}

@media (orientation: landscape) and (width >= 920px) {
  .title-box {
    max-width: 45vh;
  }
}

.grecaptcha-wrapper {
  display: none;
}

.grecaptcha-wrapper + div {
  bottom: 0 !important;
  overflow: auto !important;
}

.grecaptcha-wrapper + div > div:first-child {
  position: absolute !important;
}

@media (prefers-color-scheme: dark) {
  body:not(.t-light) main {
    color: #fff;
    background-color: #030b19;
    background-image: linear-gradient(168deg, #0000 80%, #021c87 85% 88%, #02bfb5 96%);
    background-position: 96% 0;
    background-size: 130% 130%;
    animation: 8s cubic-bezier(.65, .43, .78, 1.22) forwards bgPosFadeIn, 14s linear 8s infinite bgGradPulse;
  }
}

@media (prefers-color-scheme: dark) and (prefers-reduced-motion: reduced) {
  body:not(.t-light) main {
    background-position: 100% 100%;
  }
}

@media (prefers-color-scheme: dark) and (width >= 920px) {
  body:not(.t-light) main {
    background-image: linear-gradient(168deg, #0000 70%, #021c87 75% 78%, #02bfb5 96%);
  }
}

@media (prefers-color-scheme: dark) {
  body:not(.t-light) a {
    color: #fff;
  }

  body:not(.t-light) input:not(:placeholder-shown), body:not(.t-light) textarea:not(:placeholder-shown) {
    background: #030b19;
  }

  body:not(.t-light) .icon-logotype {
    display: none;
  }

  body:not(.t-light) .btn.fill {
    color: #071633;
    background: #fff;
    transition-property: color, background-color;
  }

  body:not(.t-light) .btn.fill.active, body:not(.t-light) .btn.fill:active, body:not(.t-light) .btn.fill:hover {
    color: #fff;
    background: #063eb4;
  }

  body:not(.t-light) .btn.fill.active .icon, body:not(.t-light) .btn.fill:active .icon, body:not(.t-light) .btn.fill:hover .icon {
    fill: currentColor;
    stroke: currentColor;
  }

  body:not(.t-light) .btn.outline {
    color: inherit;
    background: none;
    transition: color .2s ease-in-out;
  }

  body:not(.t-light) .btn.outline.active, body:not(.t-light) .btn.outline:active, body:not(.t-light) .btn.outline:hover {
    color: #0000;
    -webkit-text-fill-color: transparent;
    background-color: #053db3;
    background-image: linear-gradient(140deg, #ff5f49 35%, #053db3 80%);
    -webkit-background-clip: text;
    background-clip: text;
  }
}

body.t-dark main {
  color: #fff;
  background-color: #030b19;
  background-image: linear-gradient(168deg, #0000 80%, #021c87 85% 88%, #02bfb5 96%);
  background-position: 96% 0;
  background-size: 130% 130%;
  animation: 8s cubic-bezier(.35, 1.13, .78, 1.22) forwards bgPosFadeIn, 14s linear 8s infinite bgGradPulse;
}

@media (prefers-reduced-motion: reduced) {
  body.t-dark main {
    background-position: 100% 100%;
  }
}

@media (width >= 920px) {
  body.t-dark main {
    background-image: linear-gradient(168deg, #0000 70%, #021c87 75% 78%, #02bfb5 96%);
  }
}

body.t-dark a {
  color: #fff;
}

body.t-dark input:not(:placeholder-shown), body.t-dark textarea:not(:placeholder-shown) {
  background: #030b19;
}

body.t-dark .icon-logotype {
  display: none;
}

body.t-dark .btn.fill {
  color: #071633;
  background: #fff;
  transition-property: color, background-color;
}

body.t-dark .btn.fill.active, body.t-dark .btn.fill:active, body.t-dark .btn.fill:hover {
  color: #fff;
  background: #063eb4;
}

body.t-dark .btn.fill.active .icon, body.t-dark .btn.fill:active .icon, body.t-dark .btn.fill:hover .icon {
  fill: currentColor;
  stroke: currentColor;
}

body.t-dark .btn.outline {
  color: inherit;
  background: none;
  transition: color .2s ease-in-out;
}

body.t-dark .btn.outline.active, body.t-dark .btn.outline:active, body.t-dark .btn.outline:hover {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background-color: #053db3;
  background-image: linear-gradient(140deg, #ff5f49 35%, #053db3 80%);
  -webkit-background-clip: text;
  background-clip: text;
}

.relative {
  position: relative;
}

.mt-1 {
  margin-top: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 25px;
}

.mb-7 {
  margin-bottom: 45px;
}

.d-f {
  display: flex;
}

.d-b {
  display: block;
}

.d-ib {
  display: inline-block;
}

.d-n {
  display: none;
}

@media (width >= 920px) {
  .d-md-b {
    display: block;
  }

  .d-md-n {
    display: none;
  }
}

.tt-u {
  text-transform: uppercase;
}

.w-100p {
  width: 100%;
}

.h-100p {
  height: 100%;
}

@media (width >= 920px) {
  .w-md-60p {
    width: 60%;
  }
}

.lh-1 {
  line-height: 1;
}

.f-col {
  flex-flow: column;
}

.f-grow {
  flex-grow: 1;
}

.jc-sb {
  justify-content: space-between;
}

@media (width >= 920px) {
  .f-md-grow-0 {
    flex-grow: 0;
  }
}

.t-c {
  text-align: center;
}

.c-grad {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background-color: #053db3;
  background-image: linear-gradient(140deg, #ff5f49 35%, #053db3 80%);
  -webkit-background-clip: text;
  background-clip: text;
}

.us-none {
  -webkit-user-select: none;
  user-select: none;
}

.us-text {
  -webkit-user-select: text;
  user-select: text;
}

.ws-n {
  white-space: nowrap;
}

.cur-d {
  cursor: default;
}

.pe-n {
  pointer-events: none;
}

.wrap-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.topline {
  transform: translateY(20%);
}

.topline__item {
  vertical-align: top;
  position: relative;
  top: -.2em;
}

.fadeInUp {
  animation: .6s paused fadeInUp;
}

.fadeInUp.in {
  animation-play-state: running;
}

@media (prefers-reduced-motion: reduce) {
  :not(symbol):not(circle):not(path) {
    transition-duration: .001ms !important;
    animation-duration: .001ms !important;
  }

  .fadeInUp {
    animation-play-state: running;
  }
}

@keyframes canvasSteppedShake {
  0% {
    transform: translate3d(.5%, 0, 0);
  }

  10% {
    transform: translate3d(-.5%, 0, 0);
  }

  20% {
    transform: translate3d(1.5%, 1%, 0);
  }

  30% {
    transform: translate3d(-.25%, 0, 0);
  }

  40% {
    transform: translate3d(1.5%, .1%, 0);
  }

  50% {
    transform: translate3d(.25%, 0, 0);
  }

  60% {
    transform: translate3d(.5%, -.4%, 0);
  }

  70% {
    transform: translate3d(-1.5%, .5%, 0);
  }

  80% {
    transform: translate3d(-.25%, -.25%, 0);
  }

  90% {
    transform: translate3d(-.55%, .1%, 0);
  }

  100% {
    transform: translate3d(.25%, -.6%, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 25%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rippleLogo {
  0% {
    transform: scale(.6);
  }

  40% {
    transform: scale(1.09);
  }

  100% {
    transform: none;
  }
}

@keyframes rippleLogoInner {
  0% {
    transform: scale(.4);
  }

  10% {
    transform: none;
  }

  34% {
    transform: scale(.93);
  }

  45% {
    transform: none;
  }

  56% {
    transform: scale(.98);
  }

  67% {
    transform: none;
  }

  78% {
    transform: scale(.99);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes loadingPulse {
  30% {
    filter: saturate(.2) brightness(5);
  }

  85% {
    filter: saturate(.2) brightness(5);
  }
}

@keyframes bgPosFadeIn {
  100% {
    background-position: 96% 96%;
  }
}

@keyframes bgGradPulse {
  45% {
    background-position: 80% 80%;
  }

  92% {
    background-position: 100% 100%;
  }
}
/*# sourceMappingURL=index.18a39f5f.css.map */
