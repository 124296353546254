@use 'sass:map';

@use 'variables';

.content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-flow: column;

    @media (orientation:landscape) and (min-width: #{variables.$breakpoint3}) {
        z-index: 1;
        display: flex;
        flex-flow: row-reverse;
    }
}

.mobile-nav-wrapper {
    z-index: 2;
    position: absolute;
    right: -5px;
    display: flex;
    flex-flow: column;
}

.logo-wrapper {
    overflow: hidden;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-flow: column;
    margin: auto 0;
    height: 72px;
    max-height: 20vh;

    @media (orientation:landscape) and (min-width: #{variables.$breakpoint3}) {
        margin-top: calc(24.5vh - #{map.get(variables.$spacings, 6)});
        padding: map.get(variables.$spacings, 3) 0;
        width: 60%;
        height: 45vh;
        max-height: none;
    }

    @media (orientation: landscape) and (min-width: #{variables.$breakpoint3}) and (min-height: #{variables.$breakpoint1}) {
        margin-top: calc(14.5vh - #{map.get(variables.$spacings, 3)});
        padding: 10vh 0;
        max-height: none;
    }
}

.contact-col {
    @media (orientation:landscape) and (min-width: #{variables.$breakpoint3}) {
        flex-grow: 1;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
    }

    &__cta {
        display: none;
        margin-top: -0.2em;
        margin-left: map.get(variables.$spacings, 4);
        max-width: 20ch;

        @media (min-width: #{variables.$breakpoint1}) {
            display: block;
        }
    }
}

.icon-logomark {
    display: block;
    width: 100%;
    stroke: none;

    &--img {
        flex-grow: 1;
        height: 100%;
    }

    path,
    use {
        visibility: hidden;

        @media (prefers-reduced-motion: reduce) {
            visibility: visible;
        }
    }
}

.icon-logotype {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 1em;
    height: 1em;
    font-size: 112.5vw;
    line-height: 0.2;
    color: variables.$colorPrimaryInverted;
    border-radius: 50%;
    transform: translate(-50%, -50%);

    @media (orientation: landscape) {
        font-size: 112.5vh;
    }

    .icon {
        height: 100%;
        width: 100%;
        opacity: 0;
        animation:
            0.8s rippleLogo 0.6s forwards,
            0.4s fadeIn 0.6s forwards;
        animation-timing-function: cubic-bezier(0.65, 0.43, 0.67, 1.53);
        stroke: none;
    }

    &__circle {
        transform-origin: center bottom;
        animation: ease-out 2.5s rippleLogoInner 0.6s forwards;

        @media (prefers-reduced-motion: reduce) {
            animation: none;
        }
    }
}

.title-box {
    max-width: 12rem;

    @media (min-height: #{variables.$breakpoint2}) {
        max-width: 36vh;
    }

    @media (orientation:landscape) and (min-width: #{variables.$breakpoint3}) {
        max-width: 45vh;
    }
}

.grecaptcha-wrapper {
    display: none;

    & + div {
        bottom: 0 !important;
        overflow: auto !important;

        & > div:first-child {
            position: absolute !important;
        }
    }
}
