@use 'variables';

@media (prefers-color-scheme: dark) {
    body:not(.t-light) {
        main {
            color: variables.$colorPrimaryInverted;
            background-color: variables.$darkModeBg;
            background-image: linear-gradient(
                168deg,
                transparent 80%,
                #{variables.$darkModeGradSecondary} 85%,
                #{variables.$darkModeGradSecondary} 88%,
                #{variables.$darkModeGradPrimary} 96%
            );
            background-size: 130% 130%;
            background-position: 96% 0;
            animation:
                cubic-bezier(0.65, 0.43, 0.78, 1.22) 8s bgPosFadeIn forwards,
                linear 14s bgGradPulse 8s infinite;

            @media (prefers-reduced-motion: reduced) {
                background-position: 100% 100%;
            }

            @media (min-width: #{variables.$breakpoint3}) {
                background-image: linear-gradient(
                    168deg,
                    transparent 70%,
                    #{variables.$darkModeGradSecondary} 75%,
                    #{variables.$darkModeGradSecondary} 78%,
                    #{variables.$darkModeGradPrimary} 96%
                );
            }
        }

        a {
            color: variables.$colorPrimaryInverted;
        }

        input,
        textarea {
            &:not(:placeholder-shown) {
                background: variables.$darkModeBg;
            }
        }

        .icon-logotype {
            display: none;
        }
    }

    .btn {
        body:not(.t-light) &.fill {
            color: variables.$colorPrimary;
            background: variables.$colorPrimaryInverted;
            transition-property: color, background-color;

            &.active,
            &:active,
            &:hover {
                color: variables.$colorPrimaryInverted;
                background: variables.$darkModePrimaryInverted;

                .icon {
                    fill: currentColor;
                    stroke: currentColor;
                }
            }
        }

        &.outline {
            body:not(.t-light) & {
                background: transparent;
                color: inherit;
                transition: ease-in-out 0.2s color;

                &.active,
                &:active,
                &:hover {
                    @include variables.gradColor;
                }
            }
        }
    }
}

body.t-dark {
    main {
        color: variables.$colorPrimaryInverted;
        background-color: variables.$darkModeBg;
        background-image: linear-gradient(
            168deg,
            transparent 80%,
            #{variables.$darkModeGradSecondary} 85%,
            #{variables.$darkModeGradSecondary} 88%,
            #{variables.$darkModeGradPrimary} 96%
        );
        background-size: 130% 130%;
        background-position: 96% 0;
        animation:
            cubic-bezier(0.35, 1.13, 0.78, 1.22) 8s bgPosFadeIn forwards,
            linear 14s bgGradPulse 8s infinite;

        @media (prefers-reduced-motion: reduced) {
            background-position: 100% 100%;
        }

        @media (min-width: #{variables.$breakpoint3}) {
            background-image: linear-gradient(
                168deg,
                transparent 70%,
                #{variables.$darkModeGradSecondary} 75%,
                #{variables.$darkModeGradSecondary} 78%,
                #{variables.$darkModeGradPrimary} 96%
            );
        }
    }

    a {
        color: variables.$colorPrimaryInverted;
    }

    input,
    textarea {
        &:not(:placeholder-shown) {
            background: variables.$darkModeBg;
        }
    }

    .icon-logotype {
        display: none;
    }

    .btn {
        &.fill {
            color: variables.$colorPrimary;
            background: variables.$colorPrimaryInverted;
            transition-property: color, background-color;

            &.active,
            &:active,
            &:hover {
                color: variables.$colorPrimaryInverted;
                background: variables.$darkModePrimaryInverted;

                .icon {
                    fill: currentColor;
                    stroke: currentColor;
                }
            }
        }

        &.outline {
            background: transparent;
            color: inherit;
            transition: ease-in-out 0.2s color;

            &.active,
            &:active,
            &:hover {
                @include variables.gradColor;
            }
        }
    }
}
